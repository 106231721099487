import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';


const CustomerLookup = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [customerDetails, setCustomerDetails] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Retrieve payment details from query params
  const paymentId = searchParams.get('paymentId');
  const amount = searchParams.get('amount');
  const currency = searchParams.get('currency');

  const handleCustomerLookup = async () => {
    if (!mobileNumber && !uniqueId) {
      setError('Please enter a phone number or unique ID.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.get('/api/customer-lookup', {
        params: {
          mobileNumber: mobileNumber || undefined,
          countryCode: countryCode || undefined,
          uniqueId: uniqueId || undefined,
        },
      });

      if (response.data) {
        setCustomerDetails(response.data); // Set customer details on success
        setError('');
      } else {
        setError('Customer not found. Please check your input.');
      }
    } catch (err) {
      console.error('Customer lookup failed:', err.message);
      setError('Error occurred during lookup. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmAndSendReceipt = async () => {
    if (!customerDetails) {
      setError('No customer details available to send the receipt.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.post('/send-receipt', {
        customerId: customerDetails.customerId,
        paymentDetails: { paymentId, amount, currency },
      });

      if (response.data.message) {
        alert('Receipt sent successfully!');
        navigate('/transaction-complete'); // Redirect to a confirmation page
      } else {
        setError('Failed to send receipt. Please try again.');
      }
    } catch (err) {
      console.error('Error sending receipt:', err.message);
      setError('Error occurred while sending receipt.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Customer Lookup</h1>
      {customerDetails ? (
        <div>
          <h2>Customer Found</h2>
          <p><strong>Name:</strong> {customerDetails.name}</p>
          <p><strong>ID:</strong> {customerDetails.customerId}</p>
          <button
            onClick={handleConfirmAndSendReceipt}
            disabled={loading}
            style={{ padding: '10px 20px', marginTop: '20px' }}
          >
            {loading ? 'Sending...' : 'Confirm & Send Receipt'}
          </button>
        </div>
      ) : (
        <div>
          <p>Enter the phone number, country code, or unique ID to look up the customer.</p>
          <input
            type="text"
            placeholder="Phone Number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            style={{ padding: '10px', margin: '10px', width: '200px' }}
          />
          <input
            type="text"
            placeholder="Country Code"
            value={countryCode}
            onChange={(e) => setCountryCode(e.target.value)}
            style={{ padding: '10px', margin: '10px', width: '100px' }}
          />
          <input
            type="text"
            placeholder="Unique ID"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
            style={{ padding: '10px', margin: '10px', width: '200px' }}
          />
          <button
            onClick={handleCustomerLookup}
            disabled={loading}
            style={{ padding: '10px 20px', marginTop: '20px' }}
          >
            {loading ? 'Searching...' : 'Search'}
          </button>
        </div>
      )}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default CustomerLookup;
